var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "choose-product" }, [
    _c(
      "div",
      { staticClass: "choose_form" },
      [
        _c("el-input", {
          staticClass: "ml30 mb20",
          staticStyle: { width: "300px" },
          attrs: { size: "medium", placeholder: "请输入产品编码" },
          on: { input: _vm.getProCode },
          model: {
            value: _vm.parms.selectProCode,
            callback: function ($$v) {
              _vm.$set(_vm.parms, "selectProCode", $$v)
            },
            expression: "parms.selectProCode",
          },
        }),
        _c("el-input", {
          staticClass: "ml30 mb20",
          staticStyle: { width: "300px" },
          attrs: { size: "medium", placeholder: "请输入产品名称" },
          on: { input: _vm.getProName },
          model: {
            value: _vm.parms.selectProName,
            callback: function ($$v) {
              _vm.$set(_vm.parms, "selectProName", $$v)
            },
            expression: "parms.selectProName",
          },
        }),
        _c("el-input", {
          staticClass: "ml30 mb20",
          staticStyle: { width: "300px" },
          attrs: { size: "medium", placeholder: "请输入合同编码" },
          on: { input: _vm.getCode },
          model: {
            value: _vm.parms.code,
            callback: function ($$v) {
              _vm.$set(_vm.parms, "code", $$v)
            },
            expression: "parms.code",
          },
        }),
        _c("el-input", {
          staticClass: "ml30 mb20",
          staticStyle: { width: "300px" },
          attrs: { size: "medium", placeholder: "请输入客户名称" },
          on: { input: _vm.getcusName },
          model: {
            value: _vm.parms.cusName,
            callback: function ($$v) {
              _vm.$set(_vm.parms, "cusName", $$v)
            },
            expression: "parms.cusName",
          },
        }),
        _c("el-input", {
          staticClass: "ml30 mb20",
          staticStyle: { width: "300px" },
          attrs: { size: "medium", placeholder: "请输入客户编码" },
          on: { input: _vm.getcusCode },
          model: {
            value: _vm.parms.cusCode,
            callback: function ($$v) {
              _vm.$set(_vm.parms, "cusCode", $$v)
            },
            expression: "parms.cusCode",
          },
        }),
        _c(
          "el-select",
          {
            staticClass: "ml30 mb20",
            staticStyle: { width: "300px" },
            attrs: { placeholder: "请选择渠道" },
            on: { change: _vm.clickChannel },
            model: {
              value: _vm.parms.ext36,
              callback: function ($$v) {
                _vm.$set(_vm.parms, "ext36", $$v)
              },
              expression: "parms.ext36",
            },
          },
          _vm._l(_vm.channelList, function (item) {
            return _c("el-option", {
              key: item.dictCode,
              attrs: { label: item.dictValue, value: item.dictCode },
            })
          }),
          1
        ),
        _c(
          "div",
          { staticClass: "btnSreach" },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.cellClick } },
              [_vm._v("查询")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "choose_table" },
      [
        _c(
          "el-table",
          {
            ref: "multipleTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              "tooltip-effect": "dark",
              border: "",
              height: "340px",
              "header-cell-style": {
                backgroundColor: "#f2f2f2",
                color: "#333333",
                lineHeight: "40px",
                padding: 0,
              },
              "row-style": { lineHeight: "50px" },
            },
            on: { "selection-change": _vm.handleSelectionChange },
          },
          [
            _c("el-table-column", {
              attrs: { type: "selection", width: "55" },
            }),
            _c("el-table-column", {
              attrs: { label: "合同编号", prop: "code" },
            }),
            _c("el-table-column", {
              attrs: { prop: "cusName", label: "客户名称" },
            }),
            _c("el-table-column", {
              attrs: { prop: "cusCode", label: "客户编码" },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table_pag" },
          [
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.parms.pageNum,
                "page-sizes": [5, 10, 15, 20, 50, 100, 150, 200],
                "page-size": _vm.parms.pageSize,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.parms.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }