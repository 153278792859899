import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import { watch } from 'vue';
// import proFailform from './product_failure';
import chooseProducts from './choose_product.vue';

formCreate.component('ChooseProduct', chooseProducts);
// formCreate.component('proFailform', proFailform);
export default {
  extends: Form,
  data() {
    return {
      formFunctionCode: 'proFail',
      buttonText: {
        submit: '确认',
        close: '取消',
      },
      // 传true则显示该按钮
      buttons: {
        submit: true, // 点击会执行submit(btnType)函数，接收bntType为：1
        close: true, // 点击会调用this.closeModal()关闭模态框
      },
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  created() {
  },
  watch() {

  },
  methods: {
    formComplete() {
      const chooseProduct = this.getRule('chooseProduct');
      chooseProduct.props = {
        ...chooseProduct.props,
        dataObj: this.formConfig,
      };
    },
    submit() {
      const chooseProductData = this.fApi.el('chooseProduct');
      console.log(chooseProductData);
      // if (!chooseProductData.parms.selectProCode || !chooseProductData.parms.selectProName) {
      //   this.$message.error('请选择产品');
      //   return;
      // }
      if (!chooseProductData.multipleSelection.length || chooseProductData.multipleSelection.length === 0) {
        this.$message.error('请至少选择一条数据');
        return;
      }
      let enableStatus = '';
      let text = '';
      if (this.formConfig.code === 'pro_fail') {
        enableStatus = '003';
        text = '失效';
      } else if (this.formConfig.code === 'pro_success') {
        enableStatus = '009';
        text = '生效';
      }
      const parms = {
        contractList: [],
        productCode: chooseProductData.parms.selectProCode,
        productName: chooseProductData.parms.selectProName,
        enableStatus,
      };
      chooseProductData.multipleSelection.forEach((v) => {
        parms.contractList.push(v.code);
      });
      this.$confirm(`确认要将选中的产品${text}吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        request.post('/dms/dms/contract/disableContractProduct', parms)
          .then((res) => {
            if (res.success) {
              this.$emit('onClose');
              this.$emit('onGetList');
            }
          });
      });
    },

  },
};
